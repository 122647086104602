import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const Input = styled.input`
    width: 285px;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;

    @media (max-width:480px)  {
        width: 260px;
    }

    @media (max-width:320px)  {
        width: 225px;
    }
`;

export const EmailInputWrapper = styled.div`
    width: 330px;
    margin-left: 20px;
    margin-top: 20px;

    @media (max-width:480px)  {
        width: 305px;
    }

    @media (max-width:320px)  {
        width: 270px;
    }
`;

export const PasswordForgot = styled(LinkRouter)`
    margin-right: 225px;
    text-decoration: none;
    font-size: 12px;
    color: #ababab;

    @media (max-width:480px)  {
        margin-right: 180px;
    }

    @media (max-width:320px)  {
        margin-right: 150px;
    }
`;