import { Outlet } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import useRefreshToken from "../../Hooks/useRefreshToken";
import { UsersContext } from '../../context/UsersContext';

const PersistLogin = ({ setAuth }) => {

    const [presist, setPresist] = useState(true);
    const refreshToken = useRefreshToken();
    const { userAuth } = useContext(UsersContext);
    
    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refreshToken().then(value => {
                    if (value) {
                        setAuth(true);
                    } 
                  }).catch(err => {
                    console.log(err);
                    setAuth(false);
                  });
            }
            catch (err) {
                console.error(err);
            }
            finally {
                isMounted && setPresist(false);
            }
        }

        !userAuth?.accessToken ? verifyRefreshToken() : setPresist(false);

        return () => isMounted = false;
    }, [])

    return (
        <>
            {presist
                ? <p>Loading...</p>
                : <Outlet />
            } 
        </>
    )
}

export default PersistLogin