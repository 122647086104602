import React from 'react';
import Archived from '../components/Archived';
import Navbar from '../components/Navbar';

const ArchivedPage = ({ setAuth }) => {
  return (
    <>
        <Navbar setAuth={setAuth} />
        <Archived setAuth={setAuth} />
     </>
  )
};

export default ArchivedPage;
