import React from 'react';
import Register from '../components/Register';
import Navbar from '../components/Navbar';

const RegisterPage = ({ setAuth }) => {
  return (
    <>
        <Navbar setAuth={setAuth} />
        <Register setAuth={setAuth} />
    </>
  )
};

export default RegisterPage;