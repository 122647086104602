import React, { useContext } from 'react';
import {
  NavBar,
  NavLink,
  LeftContainer,
  BanLink
} from "./NavbarElements";
import { MdDeleteForever } from 'react-icons/md';
import useLogout from '../../Hooks/UseLogout';
import { useParams } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import { UsersContext } from '../../context/UsersContext';

const Navbar = ({ setAuth }) => {

  const { check } = useParams();

  const logout = useLogout();

  const { userAuth } = useContext(UsersContext);

  const signOut = async (e) => {
    await logout(e);
    setAuth(false);
  };

  return (
    <NavBar>
      <NavLink to={"/home/" + check}>Home</NavLink>
      <NavLink to={"/requests/" + check}>Requests</NavLink>
      <NavLink to={"/declined/"+ check}>Declined</NavLink>
      <NavLink to={"/accepted/"+ check}>Accepted</NavLink>
      <NavLink to={"/sells/"+ check}>Sells</NavLink>
      <NavLink to={"/reviews/"+ check}>Reviews</NavLink>
      <NavLink to={"/verify/"+ check}>Verified</NavLink>
      <LeftContainer>
        <BanLink to={"/66/"+ check}><MdDeleteForever /></BanLink>
        <BanLink to={"/intusers/"+ check}><FaUsers /></BanLink>
        <span></span>
        <NavLink onClick={e => signOut(e)} to="/start">Logout</NavLink>
      </LeftContainer>
    </NavBar>
  )
};

export default Navbar;
