import { useState, useContext } from 'react';
import { UsersContext } from '../../context/UsersContext';

const useForm = (validate, setAuth) => {
  const [values, setValues] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({});

  const { userAuth, setUserAuth } = useContext(UsersContext);

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    try {

      setErrors(validate(values));

      const baseURL =
        process.env.NODE_ENV === "production"
          ? "/api/"
          : "http://localhost:3001/api/";

      const sendResponse = async () => {
        await fetch(baseURL+'login', {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(values)
        }).then(res => res.json()).then(data => {
          if (data.accessToken !== undefined) {
            setUserAuth({...userAuth, accessToken: data.accessToken, user: data.userid });
            setAuth(true);
          } else {
            setAuth(false);
          }
        }).catch(error => console.error('Error:', error));
      }

      if (Object.keys(validate(values)).length === 0) {
        sendResponse();
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  return { handleChange, handleSubmit, values, errors };
}

export default useForm;