import React from 'react';
import Navbar from '../components/Navbar';
import Requests from '../components/Requests';

const RequestsPage = ({ setAuth }) => {
  return (
      <>
        <Navbar setAuth={setAuth} />
        <Requests setAuth={setAuth} /> 
      </>
  )
};

export default RequestsPage;
