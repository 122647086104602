import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const NavBar = styled.div`
    overflow: hidden;
    background-color: #333;
`;

export const NavLink = styled(LinkRouter)`
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
`;

export const BanLink = styled(LinkRouter)`
    margin-right: 100px;
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
`;

export const LeftContainer = styled.button`
    float: right;
    overflow: hidden;
    background-color: #333;
    border: none;
`;