import React from 'react';
import Declined from '../components/Declined';
import Navbar from '../components/Navbar';

const DeclinedPage = ({ setAuth }) => {
  return (
    <>
    <Navbar setAuth={setAuth} />
    <Declined setAuth={setAuth} />
 </>
  )
};

export default DeclinedPage;
