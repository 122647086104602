import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';
import { DataGrid } from '@mui/x-data-grid';
import {
    TableWrapper,
    TableButton
} from './AcceptedElements';
import Popup from 'reactjs-popup';
import { UsersContext } from '../../context/UsersContext';

const Accepted = () => {

    const axiosAuth = useAuth();

    const { check } = useParams();

    const { userAuth } = useContext(UsersContext);

    const intuserid = userAuth.user;

    const [accepted, setAccepted] = useState([]);
    const [acceptedDetails, setAcceptedDetails] = useState([]);
    const [selectAccepted, setSelectAccepted] = useState([]);

    const tableInput = [];
    let table = {}

    const getAccepted = async () => {
        try {

            const response = await axiosAuth.get('/accepted/' + check, {
                headers: {
                    intid: JSON.stringify(intuserid)
                }
            });

            // const jsonData = await response.json();

            setAccepted(response.data);
        } catch (err) {
            console.error(err.message);
        }
    };

    const followup = async (id) => {
        try {
            // const declineRequest = 
            await axiosAuth.post('/followup/' + check + "/" + id, {
                intid: JSON.stringify(intuserid)
            })

            setAccepted(accepted.filter(followed => followed.acceptedid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(() => {
        getAccepted();

        const fetchAcceptedDetails = async () => {
            try {
                await axiosAuth.get('/acceptedDetails/' + check, {
                    headers: {
                        acceptedid: JSON.stringify(acceptedDetails[0]),
                        intid: JSON.stringify(intuserid)
                    }
                }).then(data => {
                    setSelectAccepted(data.data[0]);
                });
            } catch (err) {
                console.error(err.message);
            }
        };

        if (acceptedDetails[0] !== undefined) {
            fetchAcceptedDetails();
        }

    }, [acceptedDetails]);

    const columns = [
        { field: 'businessName', headerName: 'Business name', width: 180 },
        { field: 'phonenumber', headerName: 'Phone', width: 160 },
        { field: 'emailaddress', headerName: 'Email', width: 160 },
        { field: 'followup', headerName: 'Followup', width: 160 },
    ];


    if (accepted.length !== 0) {
        // tableDataArray.push(tableData)
        accepted.map(row => {
                table = {
                    id: row.acceptedid,
                    businessName: row.businessname,
                    emailaddress: row.emailaddress,
                    phonenumber: row.phonenumber,
                    followup: row.followup
                };

                tableInput.push(table)
        })
    }

    const dataRows = tableInput;

    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    return (
        <div>
            <div>
                <h1>Accepted businesses</h1>
            </div>
            <TableWrapper>
                <DataGrid className="GridStyle"
                    rows={dataRows}
                    checkboxSelection={false}
                    onRowSelectionModelChange={itm => setAcceptedDetails(itm)}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </TableWrapper>
            <TableButton>
            <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-info detailsbutton">Details</button>}>
            <table className='orderview'>
                        <tbody>
                            <tr>
                                <td>Business name:</td>
                                <td>{selectAccepted.businessname}</td>
                            </tr>
                            <tr>
                                <td>First name:</td>
                                <td>{selectAccepted.firstname}</td>
                            </tr>
                            <tr>
                                <td>Last name:</td>
                                <td>{selectAccepted.lastname}</td>
                            </tr>
                            <tr>
                                <td>Phone number:</td>
                                <td>{selectAccepted.phonenumber}</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>{selectAccepted.emailaddress}</td>
                            </tr>
                            <tr>
                                <td>Date:</td>
                                <td>{selectAccepted.time_accepted}</td>
                            </tr>
                            <tr>
                                <td>Follow-up:</td>
                                <td>{selectAccepted.followup}</td>
                            </tr>
                        </tbody>
                    </table>
                </Popup>
                <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-outline-danger acceptbutton">Follow-up</button>}>
                    <p>Are you sure you want to follow-up?</p>
                    <button className="btn btn-info detailsbuttonpopup" onClick={closeTooltip}>Cancel</button>
                    <button className="btn btn-info acceptbutton" onClick={() => {followup(selectAccepted.acceptedid); closeTooltip()}}>Follow-up</button>
                </Popup>
            </TableButton>
        </div>
    )
};

export default Accepted;
