import React from 'react';
import ForgotPassword from '../components/ForgotPassword';

const ForgotPasswordPage = () => {
  return (
    <>
        <ForgotPassword />
    </>
  )
};

export default ForgotPasswordPage;