import React, { useState } from 'react';
import { useParams, BrowserRouter as Router, Navigate, Routes, Route } from 'react-router-dom';
import './App.css';
import { UsersContextProvider } from './context/UsersContext';
import UsersPage from "./pages/users";
import IntuserPage from "./pages/intuser";
import RequestsPage from "./pages/requests";
import SellsPage from "./pages/sells";
import ReviewsPage from "./pages/reviews";
import OrderPage from "./pages/66";
import AcceptedPage from "./pages/accepted";
import ArchivedPage from "./pages/archived";
import DeclinedPage from "./pages/declined";
import VerifyPage from "./pages/verify";
// import BlacklistedPage from "./pages/blacklisted";
import RegisterPage from "./pages/register";
import StartPage from "./pages/start";
import PresistLogin from './components/PresistLogin/index'
import CheckEmailPage from "./pages/checkemail";
import ForgotPasswordPage from './pages/forgotpassword';
import ResetPassPage from './pages/resetpass';

// delete only in nav and possible if in table admin = 1, verify tab only in nav and possible if verify in table = 1, rights tab only in nav if jedi = 1
// request ban -> black listed , black listed seller is business name email address, blacklisted buyer is email 
// order 66 blacklist requests -> accept -> decline -> blacklist
// add emails after actions 

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const setAuth = boolean => {
    setIsAuthenticated(boolean);
  };

  return (
    <UsersContextProvider>
      <Router>
        <Routes>
          {/* <Route path="*" element={<Navigate to="/logout" replace />} /> */}
          <Route element={<PresistLogin setAuth={setAuth} />}>
            <Route
              path="start"
              element={
                !isAuthenticated ? (
                  <StartPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/checkemail" />
                )
              }
            />
            <Route
              path="forgotpassword"
              element={
                !isAuthenticated ? (
                  <ForgotPasswordPage />
                ) : (
                  <Navigate to="/checkemail" />
                )
              }
            />
            <Route path="resetpw/:token" element={ <ResetPassPage /> } />
            <Route
              path="register/:check"
              element={
                isAuthenticated ? (
                  <RegisterPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/start" />
                )
              }
            />
            <Route
              path="checkemail"
              element={
                isAuthenticated ? (
                  <CheckEmailPage />
                ) : (
                  <Navigate to="/start" />
                )
              }
            />
            <Route
              path="home/:check"
              element={
                isAuthenticated ? (
                  <UsersPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/start" />
                )
              }
            />
            <Route
              path="intusers/:check"
              element={
                isAuthenticated ? (
                  <IntuserPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/start" />
                )
              }
            />
            <Route
              path="requests/:check"
              element={
                isAuthenticated ? (
                  <RequestsPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/start" />
                )
              }
            />
            <Route
              path="sells/:check"
              element={
                isAuthenticated ? (
                  <SellsPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/start" />
                )
              }
            />
            <Route
              path="reviews/:check"
              element={
                isAuthenticated ? (
                  <ReviewsPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/start" />
                )
              }
            />
            <Route
              path="declined/:check"
              element={
                isAuthenticated ? (
                  <DeclinedPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/start" />
                )
              }
            />
            <Route
              path="verify/:check"
              element={
                isAuthenticated ? (
                  <VerifyPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/start" />
                )
              }
            />
            <Route
              path="66/:check"
              element={
                isAuthenticated ? (
                  <OrderPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/start" />
                )
              }
            />
            <Route
              path="accepted/:check"
              element={
                isAuthenticated ? (
                  <AcceptedPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/start" />
                )
              }
            />
            <Route
              path="archived/:check"
              element={
                isAuthenticated ? (
                  <ArchivedPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/start" />
                )
              }
            />
            {/* <Route
              path="blacklisted/:check"
              element={
                isAuthenticated ? (
                  <BlacklistedPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/start" />
                )
              }
            /> */}
          </Route>
        </Routes>
      </Router>
    </UsersContextProvider>
  );
}

export default App;
