import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';
import { DataGrid } from '@mui/x-data-grid';
import {
    TableWrapper,
    TableButton
} from './DeclinedElements';
import '../globalstyle.css';
import Popup from 'reactjs-popup';
import { UsersContext } from '../../context/UsersContext';

const Declined = () => {

    const axiosAuth = useAuth();

    const { check } = useParams();

    const { userAuth } = useContext(UsersContext);

    const intuserid = userAuth.user;

    const [declined, setDeclined] = useState([]);
    const [declinedDetails, setDeclinedDetails] = useState([]);
    const [selectDeclined, setSelectDeclined] = useState([]);

    const tableInput = [];
    let table = {}

    const getDeclined = async () => {
        try {

            const response = await axiosAuth.get('/declined/' + check, {
                headers: {
                    intid: JSON.stringify(intuserid)
                }
            });

            //   const jsonData = await response.json();

            setDeclined(response.data);
        } catch (err) {
            console.error(err.message);
        }
    };

    const deleteRequest = async (id) => {
        try {
            // const declineRequest = 
            await axiosAuth.post('/deleterequest/' + check + "/" + id, {
                intid: JSON.stringify(intuserid)
            });

            setDeclined(declined.filter(decline => decline.requestid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }


    const rollbackRequest = async (id) => {
        try {
            // const declineRequest = 
            await axiosAuth.post('/rollbackdecline/' + check + "/" + id, {
                intid: JSON.stringify(intuserid)
            });

            setDeclined(declined.filter(decline => decline.requestid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(() => {
        getDeclined();

        const fetchDeclineDetails = async () => {
            try {
                await axiosAuth.get('/declinedDetails/' + check, {
                    headers: {
                        requestid: JSON.stringify(declinedDetails[0]),
                        intid: JSON.stringify(intuserid)
                    }
                }).then(data => {
                    setSelectDeclined(data.data[0]);
                });
            } catch (err) {
                console.error(err.message);
            }
        };

        if (declinedDetails[0] !== undefined) {
            fetchDeclineDetails();
        }

    }, [declinedDetails]);

    const columns = [
        { field: 'businessName', headerName: 'Business name', width: 180 },
        { field: 'username', headerName: 'Username', width: 180 },
        { field: 'email', headerName: 'Email', width: 160 },
        { field: 'phone', headerName: 'Phone', width: 140 },
        { field: 'place', headerName: 'Place', width: 140 }
    ];

    if (declined.length !== 0) {
        // tableDataArray.push(tableData)
        // console.log(tableDataArray)
        declined.map(row => {
                table = {
                    id: row.requestid,
                    businessName: row.businessname,
                    username: row.username,
                    email: row.emailaddress,
                    phone: row.phonenumber,
                    place: row.place
                };

                tableInput.push(table)
        })
    }

    const dataRows = tableInput;

    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    return (
        <div>
            <div>
                <h1>Declined</h1>
            </div>
            <TableWrapper>
                <DataGrid className="GridStyle"
                    rows={dataRows}
                    checkboxSelection={false}
                    onRowSelectionModelChange={itm => setDeclinedDetails(itm)}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </TableWrapper>
            <TableButton>
            <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-info detailsbutton">Details</button>}>
                    <table className='orderview'>
                        <tbody>
                        <tr>
                                <td>Business name:</td>
                                <td>{selectDeclined.businessname}</td>
                            </tr>
                            <tr>
                                <td>First name:</td>
                                <td>{selectDeclined.firstname}</td>
                            </tr>
                            <tr>
                                <td>Last name:</td>
                                <td>{selectDeclined.lastname}</td>
                            </tr>
                            <tr>
                                <td>Phone number:</td>
                                <td>{selectDeclined.phonenumber}</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>{selectDeclined.emailaddress}</td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>{selectDeclined.address}</td>
                            </tr>
                            <tr>
                                <td>Description:</td>
                                <td>{selectDeclined.description}</td>
                            </tr>
                            <tr>
                                <td>Website:</td>
                                <td>{selectDeclined.website}</td>
                            </tr>
                            <tr>
                                <td>Social one:</td>
                                <td>{selectDeclined.socialone}</td>
                            </tr>
                            <tr>
                                <td>Social two:</td>
                                <td>{selectDeclined.socialtwo}</td>
                            </tr>
                        </tbody>
                    </table>
                </Popup>
                <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-outline-danger acceptbutton">Rollback</button>}>
                    <p>Are you sure you want to rollback?</p>
                    <button className="btn btn-info detailsbuttonpopup" onClick={closeTooltip}>Cancel</button>
                    <button className="btn btn-info acceptbutton" onClick={() => {rollbackRequest(selectDeclined.requestid); closeTooltip()}}>Rollback</button>
                </Popup>
                <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-outline-danger banbutton">Delete</button>}>
                    <p>Are you sure you want to delete?</p>
                    <button className="btn btn-info detailsbuttonpopup" onClick={closeTooltip}>Cancel</button>
                    <button className="btn btn-outline-danger banbuttonpopup" onClick={() => {deleteRequest(selectDeclined.requestid); closeTooltip()}}>Delete</button>
                </Popup>
            </TableButton>
        </div>
    )
};

export default Declined;
