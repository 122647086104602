import React from 'react';
import validate from './validate';
import useForm from './UseForm';
import {
    Input,
    EmailInputWrapper,
} from './ForgotPasswordElements';
import "../globalstyle.css";

const ForgotPassword = () => {

  const { handleChange, handleSubmit, errors } = useForm(
    validate
  );

  return (
    <form  onSubmit={handleSubmit} noValidate>
      <EmailInputWrapper>
          <Input name="email" onChange={handleChange} placeholder="E-mail" type="email" required="required" />
          {errors.email && <p className="Errorstyle">{errors.email}</p>}
          <p></p>
          <button type="submit">Reset</button>
      </EmailInputWrapper>
    </form>
  )
}

export default ForgotPassword