import { useState, useContext } from 'react';
import { UsersContext } from '../../context/UsersContext';
import { useParams } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';

const useForm = (validate, setAuth) => {
  const [values, setValues] = useState({
    username: '',
    password: '',
    email: '',
    role: '',
    intid: ''
  });
  const [errors, setErrors] = useState({});

  const { userAuth, setUserAuth } = useContext(UsersContext);

  const axiosAuth = useAuth();

  const { check } = useParams();
  
  const intuserid = userAuth.user;
  
  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const token = "abc";

  const handleSubmit = e => {
    e.preventDefault();

    try {

      values.intid = intuserid;

      setErrors(validate(values));

      const sendResponse = async () => {
        // await fetch('http://localhost:3002/register/' + token, {
        await axiosAuth.post('/register/' + check, values
          // intid: JSON.stringify()
        ).then(data => {
          if (data === "Success") {
            console.log("Success")
          } 
        }).catch(error => console.error('Error:', error));
      }

      if (Object.keys(validate(values)).length === 0) {
        sendResponse();
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  return { handleChange, handleSubmit, values, errors };
}

export default useForm;