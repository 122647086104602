import React from 'react';
import Start from '../components/Start';

const StartPage = ({ setAuth }) => {
  return (
    <>
        <Start setAuth={setAuth} />
    </>
  )
};

export default StartPage;