import React from 'react';
import Navbar from '../components/Navbar';
import Intuser from '../components/Intuser';

const IntuserPage = ({ setAuth }) => {
  return (
    <>
      <Navbar setAuth={setAuth} />
      <Intuser setAuth={setAuth} />
    </>
)
};

export default IntuserPage;
