import React from 'react';
import ResetPass from '../components/resetpw';

const ResetPasswordPage = () => {
  return (
    <>
        <ResetPass />
    </>
  )
};

export default ResetPasswordPage;