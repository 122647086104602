import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';
import { DataGrid } from '@mui/x-data-grid';
import {
    TableWrapper,
    TableButton
} from './ArchivedElements';
import Popup from 'reactjs-popup';
import { UsersContext } from '../../context/UsersContext';

const Archived = () => {

    const axiosAuth = useAuth();

    const { check } = useParams();

    const { userAuth } = useContext(UsersContext);

    const intuserid = userAuth.user;

    const [archivedRequest, setArchived] = useState([]);
    const [archivedDetails, setArchivedDetails] = useState([]);
    const [selectArchived, setSelectArchived] = useState([]);

    const tableInput = [];
    let table = {}

    const getArchived = async () => {
        try {

            const response = await axiosAuth.get('/archived/' + check, {
                headers: {
                    intid: JSON.stringify(intuserid)
                }
            });

            // const jsonData = await response.json();

            setArchived(response.data);
        } catch (err) {
            console.error(err.message);
        }
    };


    useEffect(() => {
        getArchived();

        const fetchArchivedDetails = async () => {
            try {
                await axiosAuth.get('/archivedDetails/' + check, {
                    headers: {
                        detailid: JSON.stringify(archivedDetails[0]),
                        intid: JSON.stringify(intuserid)
                    }
                }).then(data => {
                    setSelectArchived(data.data[0]);
                });
            } catch (err) {
                console.error(err.message);
            }
        };

        if (archivedDetails[0] !== undefined) {
            fetchArchivedDetails();
        }

    }, [archivedDetails]);

    const columns = [
        { field: 'businessName', headerName: 'Business name', width: 180 },
        { field: 'place', headerName: 'Place', width: 160 },
        { field: 'stripe', headerName: 'Stripe', width: 140 }
    ];

    if (archivedRequest.length !== 0) {
        archivedRequest.map(row => {
                table = {
                    id: row.archiveid,
                    businessName: row.businessname,
                    place: row.businessaddress,
                    stripe: row.stripeaccount
                };

                tableInput.push(table)
        })
    }

    const dataRows = tableInput;

    const ref = useRef();

    return (
        <div>
            <div>
                <h1>Archived businesses</h1>
            </div>
            <TableWrapper>
                <DataGrid className="GridStyle"
                    rows={dataRows}
                    checkboxSelection={false}
                    onRowSelectionModelChange={itm => setArchivedDetails(itm)}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </TableWrapper>
            <TableButton>
            <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-info detailsbutton">Details</button>}>
            <table className='orderview'>
                        <tbody>
                            <tr>
                                <td>Business name:</td>
                                <td>{selectArchived.businessname}</td>
                            </tr>
                            <tr>
                                <td>Phone:</td>
                                <td>{selectArchived.businessphone}</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>{selectArchived.businessemail}</td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>{selectArchived.businessaddress}</td>
                            </tr>
                            <tr>
                                <td>Stripe:</td>
                                <td>{selectArchived.stripeaccount}</td>
                            </tr>
                        </tbody>
                    </table>
                </Popup>
            </TableButton>
        </div>
    )
};

export default Archived;
