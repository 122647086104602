import { useContext } from 'react';
import { UsersContext } from '../context/UsersContext';
import UserFinder from '../API/UserFinder';

const useRefreshToken = () => {

    const { setUserAuth } = useContext(UsersContext);   

    const refreshToken = async () => {
        try {
            const res = await UserFinder.get('/refreshToken', {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  }
            });
            setUserAuth(prev => {
                // console.log(JSON.stringify(prev.accessToken));
                // console.log(res.data.accessToken);
                // console.log(res.data.userid);
                return { ...prev, accessToken: res.data.accessToken, user: res.data.userid }
            });
            // return [res.data.accessToken, res.data.userid, res.data.username];
            return [res.data.accessToken];
        } catch (err) {
          console.error(err.message);
        }
    };

    return refreshToken;
}

export default useRefreshToken