import React from 'react';
import Navbar from '../components/Navbar';
import Sells from '../components/Sells';

const SellsPage = ({ setAuth }) => {
  return (
    <>
      <Navbar setAuth={setAuth} />
      <Sells setAuth={setAuth} />
    </>
)
};

export default SellsPage;
