import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import useAuth from '../../Hooks/useAuth';
import {
    TableWrapper,
    TableButton
} from './ReviewElements';
import '../globalstyle.css';
import Popup from 'reactjs-popup';
import { UsersContext } from '../../context/UsersContext';

const Reviews = () => {

    const axiosAuth = useAuth();

    const { check } = useParams();
    
    const { userAuth } = useContext(UsersContext);

    const intuserid = userAuth.user;

    const [reviews, setReviews] = useState([]);
    const [reviewDetails, setReviewDetails] = useState([]);
    const [selectReview, setSelectReview] = useState([]);

    const tableInput = [];
    let table = {}

    const getReviews = async () => {
        try {
            const response = await axiosAuth.get('/reviews/' + check, {
                headers: {
                    intid: JSON.stringify(intuserid)
                }
            });

            //   const jsonData = await response.json();

            setReviews(response.data);
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getReviews();

        const fetchReviewDetails = async () => {
            try {
                await axiosAuth.get('/reviewDetails/' + check, {
                    headers: {
                        reviewid: JSON.stringify(reviewDetails[0]),
                        intid: JSON.stringify(intuserid)
                    }
                }).then(data => {
                    setSelectReview(data.data[0]);
                });
            } catch (err) {
                console.error(err.message);
            }
        };

        if (reviewDetails[0] !== undefined) {
            fetchReviewDetails();
        }

    }, [reviewDetails]);

    const columns = [
        { field: 'businessName', headerName: 'Business name', width: 180 },
        { field: 'rating', headerName: 'Rating', width: 180 },
        { field: 'time', headerName: 'Time', width: 160 },
        { field: 'review', headerName: 'Review', width: 140 }
    ];

    if (reviews.length !== 0) {
        // tableDataArray.push(tableData)
        // console.log(tableDataArray)
        reviews.map(row => {
                table = {
                    id: row.reviewid,
                    businessName: row.shop,
                    rating: row.rating,
                    time: row.time_review,
                    review: row.review
                };

                tableInput.push(table)
        })
    }

    const dataRows = tableInput;

    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    return (
        <div>
            <div>
                <h1>Reviews</h1>
            </div>
            <TableWrapper>
                <DataGrid className="GridStyle"
                    rows={dataRows}
                    checkboxSelection={false}
                    onRowSelectionModelChange={itm => setReviewDetails(itm)}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </TableWrapper>
            <TableButton>
            <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-info detailsbutton">Details</button>}>
                    <table className='orderview'>
                        <tbody>
                            <tr>
                                <td>Shop:</td>
                                <td>{selectReview.shop}</td>
                            </tr>
                            <tr>
                                <td>Rating:</td>
                                <td>{selectReview.rating}</td>
                            </tr>
                            <tr>
                                <td>Review:</td>
                                <td>{selectReview.review}</td>
                            </tr>
                            <tr>
                                <td>Date:</td>
                                <td>{selectReview.time_review}</td>
                            </tr>
                            <tr>
                                <td>Products:</td>
                                <td>{selectReview.products}</td>
                            </tr>
                        </tbody>
                    </table>
                </Popup>
            </TableButton>
        </div>
    );
};

export default Reviews;
