import React from 'react';
import validate from './validate';
import useForm from './UseForm';
import {
    Input,
    EmailInputWrapper,
    PasswordForgot
} from './StartElements';
import "../globalstyle.css";

const Start = ({ setAuth }) => {

  const { handleChange, handleSubmit, errors } = useForm(
    validate,
    setAuth
  );

  return (
    <form  onSubmit={handleSubmit} noValidate>
      <EmailInputWrapper>
          <Input name="email" onChange={handleChange} placeholder="E-mail" type="email" required="required" />
          {errors.email && <p className="Errorstyle">{errors.email}</p>}
          <p></p>
          <Input name="password" onChange={handleChange} placeholder="Password" type="password" required="required" />
          {errors.password && <p className="Errorstyle">{errors.password}</p>}
          <p></p>
          <PasswordForgot to="/forgotpassword">Forgot password?</PasswordForgot>
          <p></p>
          <button type="submit">Log in</button>
      </EmailInputWrapper>
    </form>
  )
}

export default Start