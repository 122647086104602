import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';
import { DataGrid } from '@mui/x-data-grid';
import {
    TableWrapper,
    TableButton
} from './RequestElements';
import Popup from 'reactjs-popup';
import { UsersContext } from '../../context/UsersContext';

const Requests = () => {

    const axiosAuth = useAuth();

    const { check } = useParams();

    const { userAuth } = useContext(UsersContext);

    const intuserid = userAuth.user;

    const [requests, setRequests] = useState([]);
    const [selectRequests, setSelectRequests] = useState([]);
    const [requestDetails, setRequestDetails] = useState([]);

    const tableInput = [];
    let table = {}

    const acceptRequest = async (id) => {
        try {
            const acceptRequest =  await axiosAuth.post('/requests/' + check + "/" + id, {
                intid: JSON.stringify(intuserid)
            });

            setRequests(requests.filter(request => request.requestid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }

    const declineRequest = async (id) => {
        try {
            // const declineRequest = 
            await axiosAuth.post('/declinerequest/' + check + "/" + id, {
                intid: JSON.stringify(intuserid)
            });

            setRequests(requests.filter(request => request.requestid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }

    const deleteRequest = async (id) => {
        try {
            // const declineRequest = 
            await axiosAuth.post('/deleterequest/' + check + "/" + id, {
                intid: JSON.stringify(intuserid)
            });
    
            setRequests(requests.filter(request => request.requestid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }

    const getRequests = async () => {
        try {
            const response = await axiosAuth.get('/requests/' + check, {
                headers: {
                    intid: JSON.stringify(intuserid)
                }
            });

            // const jsonData = await response.json();

            setRequests(response.data);
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getRequests();

        const fetchRequestDetails = async () => {
            try {
                await axiosAuth.get('/requestDetails/' + check, {
                    headers: {
                        requestid: JSON.stringify(requestDetails[0]),
                        intid: JSON.stringify(intuserid)
                    }
                }).then(data => {
                    setSelectRequests(data.data[0]);
                });
            } catch (err) {
                console.error(err.message);
            }
        };

        if (requestDetails[0] !== undefined) {
            fetchRequestDetails();
        }
    }, [requestDetails]);

    const columns = [
        { field: 'businessName', headerName: 'Business name', width: 180 },
        { field: 'email', headerName: 'Email', width: 160 },
        { field: 'address', headerName: 'Address', width: 140 }
    ];

    if (requests.length !== 0) {
        // tableDataArray.push(tableData)
        // console.log(tableDataArray)
        requests.map(row => {
                table = {
                    id: row.requestid,
                    businessName: row.businessname,
                    email: row.emailaddress,
                    address: row.address
                };

                tableInput.push(table)
        })
    }

    const dataRows = tableInput;

    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    return (
        <div>
            <div>
                <h1>Requests</h1>
            </div>
            <TableWrapper>
                <DataGrid className="GridStyle"
                    rows={dataRows}
                    checkboxSelection={false}
                    onRowSelectionModelChange={itm => setRequestDetails(itm)}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </TableWrapper>
            <TableButton>
            <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-info detailsbutton">Details</button>}>
                    <table className='orderview'>
                        <tbody>
                            <tr>
                                <td>Business name:</td>
                                <td>{selectRequests.businessname}</td>
                            </tr>
                            <tr>
                                <td>First name:</td>
                                <td>{selectRequests.firstname}</td>
                            </tr>
                            <tr>
                                <td>Last name:</td>
                                <td>{selectRequests.lastname}</td>
                            </tr>
                            <tr>
                                <td>Phone number:</td>
                                <td>{selectRequests.phonenumber}</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>{selectRequests.emailaddress}</td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>{selectRequests.address}</td>
                            </tr>
                            <tr>
                                <td>Description:</td>
                                <td>{selectRequests.description}</td>
                            </tr>
                            <tr>
                                <td>Website:</td>
                                <td>{selectRequests.website}</td>
                            </tr>
                            <tr>
                                <td>Social one:</td>
                                <td>{selectRequests.socialone}</td>
                            </tr>
                            <tr>
                                <td>Social two:</td>
                                <td>{selectRequests.socialtwo}</td>
                            </tr>
                        </tbody>
                    </table>
                </Popup>
                <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-info acceptbutton">Accept</button>}>
                    <p>Are you sure you want to accept?</p>
                    <button className="btn btn-info acceptbuttonpopup" onClick={() => { acceptRequest(selectRequests.requestid); closeTooltip()}}>Accept</button>
                    <button className="btn btn-info" onClick={closeTooltip}>Cancel</button>
                </Popup>
                <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-outline-danger banbutton">Decline</button>}>
                    <p>Are you sure you want to decline?</p>
                    <button className="btn btn-info detailsbuttonpopup" onClick={closeTooltip}>Cancel</button>
                    <button className="btn btn-outline-danger" onClick={() => {declineRequest(selectRequests.requestid); closeTooltip()}}>Decline</button>
                </Popup>
            </TableButton>
        </div>
    )
};

export default Requests;
