import React from 'react'
import validate from './validate';
import useForm from './UseForm';
import {
    Input,
    Select,
    EmailInputWrapper
} from './RegisterElements';
import "../globalstyle.css";

//create a check route that will check the client url jwt to verify only that link posibile, first send email, then email login link with jwt check etc. all following routes

// database backpanel user normal user admin user true false

const Register = ({ setAuth }) => {

  const { handleChange, handleSubmit, errors, values } = useForm(
    validate,
    setAuth
  );

  return (
    <form  onSubmit={handleSubmit} noValidate>
      <EmailInputWrapper>
          <Input name="username" value={values.username} onChange={handleChange} placeholder="Username" type="text" required="required" />
          {errors.username && <p className="Errorstyle">{errors.username}</p>}
          <p></p>
          <Input name="email" value={values.email} onChange={handleChange} placeholder="E-mail" type="email" required="required" />
          {errors.email && <p className="Errorstyle">{errors.email}</p>}
          <p></p>
          <Select id="cars" name="role" value={values.role} onChange={handleChange} required="required">
            <option value="0" default>Select role</option>
            <option value="1" default>Moderate shops</option>
            <option value="2">Client manager</option>
            <option value="3">HR</option>
            <option value="4">Financial</option>
          </Select>
          {errors.role && <p className="Errorstyle">{errors.role}</p>}
          <p></p>
          <Input name="password" value={values.password} onChange={handleChange} placeholder="Password" type="password" required="required" />
          {errors.password && <p className="Errorstyle">{errors.password}</p>}
          <p></p>
          <button type="submit">Register</button>
      </EmailInputWrapper>
    </form>
  )
}

export default Register