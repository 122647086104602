import React from 'react';
import CheckEmail from '../components/CheckMail';

const CheckEmailPage = () => {
  return (
    <>
        <CheckEmail />
    </>
  )
};

export default CheckEmailPage;