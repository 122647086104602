import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';
import { DataGrid } from '@mui/x-data-grid';
import {
    TableWrapper,
    TableButton
} from './IntElements';
import '../globalstyle.css';
import Popup from 'reactjs-popup';
import { UsersContext } from '../../context/UsersContext';

const Intuser = () => {

    const axiosAuth = useAuth();

    const { check } = useParams();

    const { userAuth } = useContext(UsersContext);

    const intuserid = userAuth.user;

    const [users, setUsers] = useState([]);
    const [userDetails, setUserDetails] = useState([]);

    const tableInput = [];
    let table = {}

    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    const getUsers = async () => {
        try {
            const response = await axiosAuth.get('/intusers/' + check, {
                headers: {
                    intid: JSON.stringify(intuserid)
                }
            });

            setUsers(response.data);
        } catch (err) {
            console.error(err.message);
        }
    };

    const deleteUser = async (id) => {
        try {
            // const declineRequest = 
            await axiosAuth.post('/deleteuser/' + check + "/" + id, {
                intid: JSON.stringify(intuserid)
            });

            setUsers(users.filter(user => user.userid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }

    const deRoleUser = async (id) => {
        try {
            // const declineRequest = 
            await axiosAuth.post('/deRole/' + check + "/" + id, {
                intid: JSON.stringify(intuserid)
            });

            setUsers(users.filter(user => user.userid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }

    const upRoleUser = async (id) => {
        try {
            // const declineRequest = 
            await axiosAuth.post('/upRole/' + check + "/" + id, {
                intid: JSON.stringify(intuserid)
            });

            setUsers(users.filter(user => user.userid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    const columns = [
        { field: 'username', headerName: 'Username', width: 180 },
        { field: 'email', headerName: 'Email', width: 160 },
        { field: 'role', headerName: 'Role', width: 140 }
    ];

    if (users.length !== 0) {
        // tableDataArray.push(tableData)
        // console.log(tableDataArray)
        users.map(row => {
            table = {
                id: row.userid,
                username: row.username,
                email: row.email,
                role: row.role
            };

            tableInput.push(table)
        })
    }

    const dataRows = tableInput;

    return (
        <div>
            <div>
                <h1>Employees</h1>
            </div>
            <div>
                <a href={'/register/' + check}>Register</a>
            </div>
            <TableWrapper>
                <DataGrid className="GridStyle"
                    rows={dataRows}
                    checkboxSelection={false}
                    onRowSelectionModelChange={itm => setUserDetails(itm)}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </TableWrapper>
            <TableButton>
                <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-outline-danger rolebutton">Up-role</button>}>
                    <p>Are you sure you want to up-role?</p>
                    <button className="btn btn-info detailsbuttonpopup" onClick={closeTooltip}>Cancel</button>
                    <button className="btn btn-outline-danger banbuttonpopup" onClick={() => {upRoleUser(userDetails[0]); closeTooltip()}}>Up-role</button>
                </Popup>
                <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-outline-danger rolebutton">De-role</button>}>
                    <p>Are you sure you want to de-role?</p>
                    <button className="btn btn-info detailsbuttonpopup" onClick={closeTooltip}>Cancel</button>
                    <button className="btn btn-outline-danger banbuttonpopup" onClick={() => {deRoleUser(userDetails[0]); closeTooltip()}}>de-role</button>
                </Popup>
                <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-outline-danger banbutton">Delete</button>}>
                    <p>Are you sure you want to delete?</p>
                    <button className="btn btn-info detailsbuttonpopup" onClick={closeTooltip}>Cancel</button>
                    <button className="btn btn-outline-danger banbuttonpopup" onClick={() => {deleteUser(userDetails[0]); closeTooltip();}}>Delete</button>
                </Popup>
            </TableButton>
        </div>
    )
};

export default Intuser;
