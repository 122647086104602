import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';
import { DataGrid } from '@mui/x-data-grid';
import {
  TableWrapper,
  TableButton
} from './SellsElements';
import Popup from 'reactjs-popup';
import { UsersContext } from '../../context/UsersContext';

// test

const Sells = () => {

  const axiosAuth = useAuth();

  const { check } = useParams();

  const { userAuth } = useContext(UsersContext);

  const intuserid = userAuth.user;

  const [sells, setSells] = useState([]);
  const [sellDetails, setSellDetails] = useState([]);
  const [selectSell, setSelectSell] = useState([]);

  const tableInput = [];
  let table = {}

  const getSells = async () => {
    try {

      const response = await axiosAuth.get('/sells/' + check, {
        headers: {
          intid: JSON.stringify(intuserid)
        }
      });

      // const jsonData = await response.json();

      setSells(response.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getSells();

    const fetchSellDetails = async () => {
      try {
        await axiosAuth.get('/sellDetails/' + check, {
          headers: {
            orderid: JSON.stringify(sellDetails[0]),
            intid: JSON.stringify(intuserid)
          }
        }).then(data => {
          setSelectSell(data.data[0]);
        });
      } catch (err) {
        console.error(err.message);
      }
    };

    if (sellDetails[0] !== undefined) {
      fetchSellDetails();
    }
  }, [sellDetails]);

  const columns = [
    { field: 'businessName', headerName: 'Business name', width: 180 },
    { field: 'status', headerName: 'Status', width: 160 },
    { field: 'time', headerName: 'Time', width: 160 },
    { field: 'order', headerName: 'Order', width: 160 }
  ];

  if (sells.length !== 0) {
    // tableDataArray.push(tableData)
    // console.log(tableDataArray)
    sells.map(row => {
      table = {
        id: row.orderid,
        businessName: row.shop,
        status: row.status,
        time: row.order_time,
        order: row.order_link
      };

      tableInput.push(table)
    })
  }

  const dataRows = tableInput;

  const ref = useRef();
  const closeTooltip = () => ref.current.close();

  return (
    <div>
      <div>
        <h1>Sells</h1>
      </div>
      <TableWrapper>
        <DataGrid className="GridStyle"
          rows={dataRows}
          checkboxSelection={false}
          onRowSelectionModelChange={itm => setSellDetails(itm)}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </TableWrapper>
      <TableButton>
        <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-info detailsbutton">Details</button>}>
          <table className='orderview'>
            <tbody>
              <tr>
                <td>Shop:</td>
                <td>{selectSell.shop}</td>
              </tr>
              <tr>
                <td>Status:</td>
                <td>{selectSell.status}</td>
              </tr>
              <tr>
                <td>Time:</td>
                <td>{selectSell.order_time}</td>
              </tr>
              <tr>
                <td>Order:</td>
                <td>{selectSell.order_link}</td>
              </tr>
              <tr>
                <td>Products:</td>
                <td>{selectSell.productnames}</td>
              </tr>
            </tbody>
          </table>
        </Popup>
      </TableButton>
    </div>
  )
};

export default Sells;
