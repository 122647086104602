import React, {useState, createContext} from "react";

export const UsersContext = createContext({});

export const UsersContextProvider = ({ children }) => {
    const {users, setUsers} = useState([])
    const [userAuth, setUserAuth] = useState({});

    return (
        <UsersContext.Provider value={{users, setUsers, userAuth, setUserAuth}}>
            {children}
        </UsersContext.Provider>
    )
}

export default UsersContext;