import React from 'react';
import Order from '../components/66';
import Navbar from '../components/Navbar';

const OrderPage = ({ setAuth }) => {
  return (
    <>
        <Navbar setAuth={setAuth} />
        <Order setAuth={setAuth} />
     </>
  )
};

export default OrderPage;
