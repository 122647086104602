export default function validate(values) {
    let errors = {};
    // let errorPasswordLogin = {};
  
    if (!values.email.trim()) {
      errors.email = 'Email address is required';
    } else if (!/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
      errors.email = 'Email address is not known';
    }

    if (!values.password) {
      errors.password = 'Password is required';
    } 
    else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/.test(values.password)) {
      errors.password = 'Password must contain at least 1 special character, 1 numeric character and 1 uppercase character';
    }
    else if (!/[A-Z]/.test(values.password)) {
      errors.password = 'Password must contain at least 1 special character, 1 numeric character and 1 uppercase character';
    }
    else if (values.password.length < 8) {
      errors.password = 'Password needs to be 8 characters or more';
    }
  
    return errors;
  }