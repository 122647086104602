import React from 'react'
import {
    Input,
    EmailInputWrapper
} from './ResetPWElements';
import useForm from './UseForm';
import validate from './validate';
import { useParams } from "react-router-dom";

const ResetPass = () => {
    const { token } = useParams();

    const { handleChange, handleSubmit, errors } = useForm(
        validate,
        token
    );

    return (
        <form onSubmit={handleSubmit} noValidate>
            <EmailInputWrapper>
                <Input name="password" onChange={handleChange} placeholder="New password" type="password" required="required" />
                {errors.password && <p className="Errorstyle">{errors.password}</p>}
                <p></p>
                <button type="submit">Reset</button>
            </EmailInputWrapper>
        </form>
    )
}

export default ResetPass
