import styled from 'styled-components'

export const TableWrapper = styled.div`
    height: 400px;
    width: 90%;
    background: whitesmoke;
    border-radius: 10px;
    margin: auto;
    padding: 10px;
    margin-top: 20px;
`;

export const TableButton = styled.div`
    width: 80%;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 50px;
    position: relative;
`;