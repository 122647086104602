export default function validate(values) {
    let errors = {};
    // let errorPasswordLogin = {};
  
    if (!values.email.trim()) {
      errors.email = 'Email address is required';
    } else if (!/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
      errors.email = 'Email address is not known';
    }
  
    return errors;
  }