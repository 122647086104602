import React from 'react';
import Navbar from '../components/Navbar';
import Reviews from '../components/Reviews';

const ReviewsPage = ({ setAuth }) => {
  return (
    <>
    <Navbar setAuth={setAuth} />
    <Reviews setAuth={setAuth} />
  </>
  )
};

export default ReviewsPage;
