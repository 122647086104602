import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';
import { DataGrid } from '@mui/x-data-grid';
import {
    TableWrapper,
    TableButton
} from './VerifyElements';
import '../globalstyle.css';
import Popup from 'reactjs-popup';
import { UsersContext } from '../../context/UsersContext';

const Verify = () => {

    const axiosAuth = useAuth();

    const { check } = useParams();

    const { userAuth } = useContext(UsersContext);

    const intuserid = userAuth.user;

    const [verify, setVerify] = useState([]);
    const [verifyDetails, setVerifyDetails] = useState([]);
    const [selectVerify, setSelectVerify] = useState([]);

    const tableInput = [];
    let table = {}

    const getVerified = async () => {
        try {

            const response = await axiosAuth.get('/verifylist/' + check, {
                headers: {
                    intid: JSON.stringify(intuserid)
                }
            });

            //   const jsonData = await response.json();

            setVerify(response.data);
        } catch (err) {
            console.error(err.message);
        }
    };


    const revokeVerify = async (id) => {
        try {
            // const declineRequest = 
            await axiosAuth.post('/unverify/' + check + "/" + id, {
                intid: JSON.stringify(intuserid)
            });

            setVerify(verify.filter(verify => verify.userid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(() => {
        getVerified();

        const fetchVerifyDetails = async () => {
            try {
                await axiosAuth.get('/verifyDetails/' + check, {
                    headers: {
                        userid: JSON.stringify(verifyDetails[0]),
                        intid: JSON.stringify(intuserid)
                    }
                }).then(data => {
                    setSelectVerify(data.data[0]);
                });
            } catch (err) {
                console.error(err.message);
            }
        };

        if (verifyDetails[0] !== undefined) {
            fetchVerifyDetails();
        }

    }, [verifyDetails]);

    const columns = [
        { field: 'businessName', headerName: 'Business name', width: 180 },
        { field: 'username', headerName: 'Username', width: 180 },
        { field: 'email', headerName: 'Email', width: 160 },
        { field: 'phone', headerName: 'Phone', width: 140 },
        { field: 'place', headerName: 'Place', width: 140 }
    ];

    if (verify.length !== 0) {
        // tableDataArray.push(tableData)
        // console.log(tableDataArray)
        verify.map(row => {
            table = {
                id: row.userid,
                businessName: row.businessname,
                username: row.username,
                email: row.emailaddress,
                phone: row.phonenumber,
                place: row.place
            };

            tableInput.push(table)
        })
    }

    const dataRows = tableInput;

    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    return (
        <div>
            <div>
                <h1>Verify</h1>
            </div>
            <TableWrapper>
                <DataGrid className="GridStyle"
                    rows={dataRows}
                    checkboxSelection={false}
                    onRowSelectionModelChange={itm => setVerifyDetails(itm)}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </TableWrapper>
            <TableButton>
            <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-info detailsbutton">Details</button>}>
                    <table className='orderview'>
                        <tbody>
                            <tr>
                                <td>Business name:</td>
                                <td>{selectVerify.businessname}</td>
                            </tr>
                            <tr>
                                <td>Username:</td>
                                <td>{selectVerify.username}</td>
                            </tr>
                            <tr>
                                <td>First name:</td>
                                <td>{selectVerify.firstname}</td>
                            </tr>
                            <tr>
                                <td>Last name:</td>
                                <td>{selectVerify.lastname}</td>
                            </tr>
                            <tr>
                                <td>Phone number:</td>
                                <td>{selectVerify.phonenumber}</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>{selectVerify.emailaddress}</td>
                            </tr>
                            <tr>
                                <td>Street name:</td>
                                <td>{selectVerify.streetname}</td>
                            </tr>
                            <tr>
                                <td>Street number:</td>
                                <td>{selectVerify.streetnumber}</td>
                            </tr>
                            <tr>
                                <td>Place:</td>
                                <td>{selectVerify.place}</td>
                            </tr>
                            <tr>
                                <td>Stripe account:</td>
                                <td>{selectVerify.stripeaccount}</td>
                            </tr>
                            <tr>
                                <td>By employee:</td>
                                <td>{selectVerify.employee}</td>
                            </tr>
                            <tr>
                                <td>Verfied date:</td>
                                <td>{selectVerify.time + " " + selectVerify.date}</td>
                            </tr>
                        </tbody>
                    </table>
                </Popup>
                <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-outline-danger banbutton">Unverify</button>}>
                    <p>Are you sure you want to unverify?</p>
                    <button className="btn btn-info detailsbuttonpopup" onClick={closeTooltip}>Cancel</button>
                    <button className="btn btn-outline-danger banbuttonpopup" onClick={() => {revokeVerify(selectVerify.userid); closeTooltip()}}>Unverify</button>
                </Popup>
            </TableButton>
        </div>
    )
};

export default Verify;
