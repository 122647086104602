import React from 'react';
import Navbar from '../components/Navbar';
import Users from '../components/Users';

const UsersPage = ({ setAuth }) => {
  return (
    <>
      <Navbar setAuth={setAuth} />
      <Users setAuth={setAuth} />
    </>
)
};

export default UsersPage;
