import React from 'react';
import Navbar from '../components/Navbar';
import Verify from '../components/Verify';

const VerifyPage = ({ setAuth }) => {
  return (
    <>
      <Navbar setAuth={setAuth} />
      <Verify setAuth={setAuth} />
    </>
)
};

export default VerifyPage;
