import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';
import { DataGrid } from '@mui/x-data-grid';
import {
    TableWrapper,
    TableButton
} from './66Elements';
import Popup from 'reactjs-popup';
import { UsersContext } from '../../context/UsersContext';

const Order = () => {

    const axiosAuth = useAuth();

    const { check } = useParams();

    const { userAuth } = useContext(UsersContext);

    const intuserid = userAuth.user;

    const [blacklistRequest, setBlacklisted] = useState([]);
    const [blacklistDetails, setBlacklistDetails] = useState([]);
    const [selectBlacklist, setSelectBlacklist] = useState([]);

    const tableInput = [];
    let table = {}

    const getBlacklisted = async () => {
        try {

            const response = await axiosAuth.get('/66/' + check, {
                headers: {
                    intid: JSON.stringify(intuserid)
                }
            });

            // const jsonData = await response.json();

            setBlacklisted(response.data);
        } catch (err) {
            console.error(err.message);
        }
    };

    const rollbackBan = async (id) => {
        try {
            // const declineRequest = 
            await axiosAuth.post('/rollbackban/' + check + "/" + id, {
                intid: JSON.stringify(intuserid)
            })

            setBlacklisted(blacklistRequest.filter(decline => decline.userid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }

    const deleteBanned = async (id) => {
        try {
            // const declineRequest = 
            await axiosAuth.post('/deleteBanned/' + check + "/" + id, {
                intid: JSON.stringify(intuserid)
            });

            setBlacklisted(blacklistRequest.filter(decline => decline.userid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(() => {
        getBlacklisted();

        const fetchBlacklistDetails = async () => {
            try {
                await axiosAuth.get('/blacklistDetails/' + check, {
                    headers: {
                        userid: JSON.stringify(blacklistDetails[0]),
                        intid: JSON.stringify(intuserid)
                    }
                }).then(data => {
                    setSelectBlacklist(data.data[0]);
                });
            } catch (err) {
                console.error(err.message);
            }
        };

        if (blacklistDetails[0] !== undefined) {
            fetchBlacklistDetails();
        }

    }, [blacklistDetails]);

    const columns = [
        { field: 'businessName', headerName: 'Business name', width: 180 },
        { field: 'username', headerName: 'Username', width: 160 },
        { field: 'place', headerName: 'Place', width: 160 },
        { field: 'email', headerName: 'Email', width: 160 },
        { field: 'phone', headerName: 'Phone', width: 160 },
        { field: 'stripe', headerName: 'Stripe', width: 140 }
    ];


    if (blacklistRequest.length !== 0) {
        // tableDataArray.push(tableData)
        // console.log(tableDataArray)
        blacklistRequest.map(row => {
                table = {
                    id: row.userid,
                    businessName: row.businessname,
                    username: row.username,
                    place: row.place,
                    email: row.emailaddress,
                    phone: row.phonenumber,
                    stripe: row.stripeaccount
                };

                tableInput.push(table)
        })
    }

    const dataRows = tableInput;

    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    return (
        <div>
            <div>
                <h1>Banned businesses</h1>
                <div>
                    <a href={'/archived/' + check}>Archived businesses</a>
                </div>
            </div>
            <TableWrapper>
                <DataGrid className="GridStyle"
                    rows={dataRows}
                    checkboxSelection={false}
                    onRowSelectionModelChange={itm => setBlacklistDetails(itm)}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </TableWrapper>
            <TableButton>
            <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-info detailsbutton">Details</button>}>
            <table className='orderview'>
                        <tbody>
                            <tr>
                                <td>Business name:</td>
                                <td>{selectBlacklist.businessname}</td>
                            </tr>
                            <tr>
                                <td>Username:</td>
                                <td>{selectBlacklist.username}</td>
                            </tr>
                            <tr>
                                <td>First name:</td>
                                <td>{selectBlacklist.firstname}</td>
                            </tr>
                            <tr>
                                <td>Last name:</td>
                                <td>{selectBlacklist.lastname}</td>
                            </tr>
                            <tr>
                                <td>Phone number:</td>
                                <td>{selectBlacklist.phonenumber}</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>{selectBlacklist.emailaddress}</td>
                            </tr>
                            <tr>
                                <td>Street name:</td>
                                <td>{selectBlacklist.streetname}</td>
                            </tr>
                            <tr>
                                <td>Street number:</td>
                                <td>{selectBlacklist.streetnumber}</td>
                            </tr>
                            <tr>
                                <td>Place:</td>
                                <td>{selectBlacklist.place}</td>
                            </tr>
                            <tr>
                                <td>Stripe account:</td>
                                <td>{selectBlacklist.stripeaccount}</td>
                            </tr>
                            <tr>
                                <td>By employee:</td>
                                <td>{selectBlacklist.employee}</td>
                            </tr>
                            <tr>
                                <td>Date listed:</td>
                                <td>{selectBlacklist.time + " " + selectBlacklist.date}</td>
                            </tr>
                        </tbody>
                    </table>
                </Popup>
                <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-outline-danger acceptbutton">Rollback</button>}>
                    <p>Are you sure you want to rollback?</p>
                    <button className="btn btn-info detailsbuttonpopup" onClick={closeTooltip}>Cancel</button>
                    <button className="btn btn-info acceptbutton" onClick={() => {rollbackBan(selectBlacklist.userid); closeTooltip()}}>Rollback</button>
                </Popup>
                <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-outline-danger banbutton">Delete</button>}>
                    <p>Are you sure you want to delete?</p>
                    <button className="btn btn-info detailsbuttonpopup" onClick={closeTooltip}>Cancel</button>
                    <button className="btn btn-outline-danger banbuttonpopup" onClick={() => {deleteBanned(selectBlacklist.userid); closeTooltip()}}>Delete</button>
                </Popup>
            </TableButton>
        </div>
    )
};

export default Order;
