import React from 'react';
import Accepted from '../components/Accepted';
import Navbar from '../components/Navbar';

const AcceptedPage = ({ setAuth }) => {
  return (
    <>
        <Navbar setAuth={setAuth} />
        <Accepted setAuth={setAuth} />
     </>
  )
};

export default AcceptedPage;
