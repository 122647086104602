import { useEffect, useContext } from "react";
import useRefreshToken from "./useRefreshToken";
import { UsersContext } from '../context/UsersContext';
import { axiosAuth }  from '../API/UserFinder';

const useAuth = () => {
    const refreshToken = useRefreshToken();
    const { userAuth } = useContext(UsersContext);

    useEffect(() => {

        const requestInterceptor = axiosAuth.interceptors.request.use(
            config => {
                if (!config.headers['token']) {
                    config.headers['token'] = `${userAuth?.accessToken}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseInterceptor = axiosAuth.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newToken = await refreshToken();
                    prevRequest.headers['token'] = `${newToken}`;
                    return axiosAuth(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosAuth.interceptors.request.eject(requestInterceptor);
            axiosAuth.interceptors.response.eject(responseInterceptor);
        }
    }, [userAuth, refreshToken]);

    return axiosAuth;
}

export default useAuth