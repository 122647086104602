import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';
import { DataGrid } from '@mui/x-data-grid';
import {
    TableWrapper,
    TableButton
} from './UserElements';
import '../globalstyle.css';
import Popup from 'reactjs-popup';
import { UsersContext } from '../../context/UsersContext';

const Users = () => {

    const axiosAuth = useAuth();

    const { check } = useParams();

    const { userAuth } = useContext(UsersContext);

    const intuserid = userAuth.user;
    
    const [users, setUsers] = useState([]);
    const [selectUser, setSelectUser] = useState([]);
    const [userDetails, setUserDetails] = useState([]);

    const tableInput = [];
    let table = {}
    
    const getUsers = async () => {
        try {
            const response = await axiosAuth.get('/users/' + check, {
                headers: {
                    intid: JSON.stringify(intuserid)
                }
            });

            setUsers(response.data);
        } catch (err) {
            console.error(err.message);
        }
    };

    const banRequest = async (id) => {
        try {
            // const declineRequest = 
            await axiosAuth.post('/ban/' + check + "/" + id, {
                intid: JSON.stringify(intuserid)
            });

            setUsers(users.filter(user => user.userid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }

    const verifyUser = async (id) => {
        try {
            await axiosAuth.post('/verify/' + check + "/" + id, {
                intid: JSON.stringify(intuserid)
            });
        } catch (err) {
            console.error(err.message);
        }
    }

    const columns = [
        { field: 'businessName', headerName: 'Business name', width: 180 },
        { field: 'username', headerName: 'Username', width: 180 },
        { field: 'email', headerName: 'Email', width: 160 },
        { field: 'phone', headerName: 'Phone', width: 140 },
        { field: 'place', headerName: 'Place', width: 140 }
    ];

    useEffect(() => {
        getUsers();

        const fetchUserDetails = async () => {
            try {
                await axiosAuth.get('/userDetails/' + check, {
                    headers: {
                        userid: JSON.stringify(userDetails[0]),
                        intid: JSON.stringify(intuserid)
                    }
                }).then(data => {
                    setSelectUser(data.data[0]);
                });
            } catch (err) {
                console.error(err.message);
            }
        };

        if (userDetails[0] !== undefined) {
            fetchUserDetails();
        }

    }, [userDetails]);

    if (users.length !== 0) {
        // tableDataArray.push(tableData)
        // console.log(tableDataArray)
        users.map(row => {
            table = {
                id: row.userid,
                businessName: row.businessname,
                username: row.username,
                email: row.emailaddress,
                phone: row.phonenumber,
                place: row.place
            };

            tableInput.push(table)
        })
    }

    const dataRows = tableInput;

    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    return (
        <div>
            <div>
                <h1>Users</h1>
            </div>
            <TableWrapper>
                <DataGrid className="GridStyle"
                    rows={dataRows}
                    checkboxSelection={false}
                    onRowSelectionModelChange={itm => setUserDetails(itm)}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </TableWrapper>
            <TableButton>
                <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-info detailsbutton">Details</button>}>
                    <table className='orderview'>
                        <tbody>
                            <tr>
                                <td>Business name:</td>
                                <td>{selectUser.businessname}</td>
                            </tr>
                            <tr>
                                <td>Username:</td>
                                <td>{selectUser.username}</td>
                            </tr>
                            <tr>
                                <td>First name:</td>
                                <td>{selectUser.firstname}</td>
                            </tr>
                            <tr>
                                <td>Last name:</td>
                                <td>{selectUser.lastname}</td>
                            </tr>
                            <tr>
                                <td>Phone number:</td>
                                <td>{selectUser.phonenumber}</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>{selectUser.emailaddress}</td>
                            </tr>
                            <tr>
                                <td>Street name:</td>
                                <td>{selectUser.streetname}</td>
                            </tr>
                            <tr>
                                <td>Street number:</td>
                                <td>{selectUser.streetnumber}</td>
                            </tr>
                            <tr>
                                <td>Place:</td>
                                <td>{selectUser.place}</td>
                            </tr>
                            <tr>
                                <td>Stripe account:</td>
                                <td>{selectUser.stripeaccount}</td>
                            </tr>
                        </tbody>
                    </table>
                </Popup>
                <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-info acceptbutton">Verify</button>}>
                    <p>Are you sure you want to verify?</p>
                    <button className="btn btn-info detailsbuttonpopup" onClick={closeTooltip}>Cancel</button>
                    <button className="btn btn-info acceptbuttonpopup" onClick={() => {verifyUser(selectUser.userid); closeTooltip()}}>Verify</button>
                </Popup>
                <Popup className="my-popup" ref={ref} trigger={<button className="btn btn-outline-danger banbutton">Ban</button>}>
                    <p>Are you sure you want to ban?</p>
                    <button className="btn btn-info detailsbuttonpopup" onClick={closeTooltip}>Cancel</button>
                    <button className="btn btn-outline-danger" onClick={() => {banRequest(selectUser.userid); closeTooltip()}}>Yes</button>
                </Popup>
            </TableButton>
        </div>
    )
};

export default Users;
