import { useContext } from "react";
import UserFinder from '../API/UserFinder';
import { UsersContext } from '../context/UsersContext';

const useLogout = () => {
    const { setUserAuth } = useContext(UsersContext);

    // console.log(setUserAuth)
    const logout = async (e) => {
        e.preventDefault()
        setUserAuth({});
        try {
            await UserFinder.get('/logout', {
                withCredentials: true
            });
            window.location.replace('/start');
            // console.log(setUserAuth)
        } catch (err) {
            console.error(err.message);
        }
    };

    return logout;
}

export default useLogout;